<template>
  <b-modal
    id="fee_guide_modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="tissGuide?.id"
          >Atualizar guia de honorários - Nº {{ form.provider_guide }}</span
        >
        <span v-else
          >Nova guia de honorários - Nº {{ form.provider_guide }}</span
        >
      </div>
      <span class="icon-box"
        ><Close class="icon stroke" @click="closeModal"
      /></span>
    </div>

    <div class="around-content">
      <GuideDataModule
        :form="form"
        :fields="{ tiss_invoice_id: true, situation: true }"
        :validated="validated"
        :readonly="readonly"
        :healthPlanId="healthPlan ? healthPlan.id : null"
        :tissBatchId="tissGuide.tiss_batch_id"
        :tissGuide="tissGuide"
      />

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados da operadora</p>
        </b-col>

        <b-col cols="6">
          <label for="ans">Registro ANS</label>
          <b-form-input maxlength="6" v-model="form.ans" />
        </b-col>

        <b-col cols="6">
          <label for="provider_guide">Nº da guia do prestador</label>
          <ProviderGuideInput
            :providerGuide="form.provider_guide"
            :validateProviderGuide="validateProviderGuide"
            type="fee"
            :validated="validated"
            :guideNumberExistsInBatch="guideNumberExistsInBatch"
            @update:provider-guide="form.provider_guide = $event"
          />
        </b-col>

        <b-col cols="5" class="mt-3">
          <b-form-group>
            <label for="hosp_req">Nº da guia de Solicitação de internação</label>
            <b-form-input
              v-if="!form.hosp_req_id"
              id="hosp_req"
              v-model="form.hosp_req_num"
              :readonly="readonly"
              placeholder="Descrever"
            />
            <multiselect
              v-else
              :value="hospitalizationGuideNumber"
              placeholder="Selecionar"
              :options="hospitalizationGuidesNumbers"
              @select="selectSolicitationGuide($event, 'hosp_req_id')"
              label="label"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nenhuma guia de solicitação encontrada, crie uma para continuar
              </template>
              <template slot="noResult"> Nenhum resultado </template>
              <!-- <template slot="afterList">
                        <li @click="openHospitalizationRequestModal">
                          <div class="multiselect__option action">
                            <Plus class="icon" />
                              Criar guia de solicitação de internação
                          </div>
                        </li>
                      </template> -->
            </multiselect>
            <b-form-invalid-feedback :state="validProp('hosp_req_num')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="mt-3 input-password">
          <b-form-group>
            <label for="password">Senha</label>
            <b-input-group>
              <b-form-input
                maxlength="20"
                placeholder="Descrever"
                autocomplete="off"
                type="text"
                v-model="form.password"
                :readonly="readonly"
                :state="validProp('password')"
              />
            </b-input-group>
            <b-form-invalid-feedback :state="validProp('password')"
              >Campo obrigatório</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="operator_guide_number"
            >Nº da guia Atribuído pela Operadora</label
          >
          <b-form-input
            maxlength="20"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.operator_guide_number"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do beneficiário (Paciente)</p>
        </b-col>

        <b-col cols="5">
          <b-form-group>
            <label for="beneficiary_name">Nome do beneficiário</label>
            <b-form-input
              maxlength="70"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.beneficiary_name"
              :readonly="true"
              :state="validProp('beneficiary_name')"
            />
            <b-form-invalid-feedback :state="validProp('beneficiary_name')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="5">
          <b-form-group>
            <label for="wallet_number">Nº da carteira do beneficiário</label>
            <b-form-input
              maxlength="20"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.wallet_number"
              :readonly="readonly"
              :state="validProp('wallet_number')"
            />
            <b-form-invalid-feedback :state="validProp('wallet_number')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <label for="newborn_care">Recém-nascido</label>
          <div class="mt-3">
            <label class="checkbox" align="center">
              <input
                type="checkbox"
                v-model="form.newborn_care"
                :disabled="readonly || (patient && patient.birthday)"
              />
              <span
                class="checkmark"
                :class="{
                  'check-disable': readonly || (patient && patient.birthday)
                }"
              >
                <Check />
              </span>
            </label>
          </div>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">
            Dados do contratado (onde foi executado o procedimento)
          </p>
        </b-col>

        <b-col cols="3">
          <label for="contracted_code_type">Tipo do código contratado</label>
          <multiselect
            v-model="form.contracted_code_type"
            placeholder="Selecionar"
            :options="contractedCodeTypesComputed"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="readonly"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <ContractedField
          :cols="4"
          label="Contratado executante"
          id="hospital_name"
          :readonly="readonly"
          :vModel="form.hospital_name"
          :validated="validated"
          :contracteds="contracteds"
          @select-contracted="selectContractedProcedure"
        />

        <b-col cols="3">
          <label for="operator_code">Código na operadora</label>
          <b-form-input
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.operator_code"
            v-mask="mask"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="2">
          <label for="contractor_cnes">CNES</label>
          <b-form-input
            maxlength="7"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.contractor_cnes"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do contratado</p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="5">
        <b-form-group>
          <ContractedField
            label="Contratado executante"
            id="performing_contractor_name"
            :readonly="readonly"
            :vModel="form.performing_contractor_name"
            :validated="validated"
            :contracteds="contracteds"
            @select-contracted="selectContracted"
            :cols="12"
          />
          <b-form-invalid-feedback :state="validProp('performing_contractor_name')">
            Campo obrigatório
          </b-form-invalid-feedback>
        </b-form-group>
        </b-col>

        <b-col cols="5">
          <b-form-group>
            <label for="contractor_code_performing"
              >Cód. do contratado executante</label
            >
            <b-form-input
              maxlength="14"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.contractor_code_performing"
              :readonly="readonly"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <label for="executer_cnes_code">CNES</label>
          <b-form-input
            maxlength="7"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.executer_cnes_code"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados da internação</p>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="invoicing_start_day">Data do inicio do faturamento</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.invoicing_start_day"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <b-form-invalid-feedback :state="validProp('invoicing_start_day')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="invoicing_end_day">Data do fim do faturamento</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.invoicing_end_day"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <b-form-invalid-feedback :state="validProp('invoicing_end_day')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="2" class="col-checks">
          <label id="apartment" class="checkbox title-styles" align="center">
            Apartamento?
            <input
              type="checkbox"
              v-model="form.apartment"
              :disabled="readonly || disabledCheckbox"
            />
            <span
              class="checkmark"
              :class="{ 'check-disable': readonly || disabledCheckbox }"
            >
              <Check />
            </span>
          </label>
          <b-tooltip
            v-if="disabledCheckbox"
            target="apartment"
            triggers="hover"
          >
            Não é possível alterar esta opção quando existem procedimentos
            cadastrados
          </b-tooltip>
        </b-col>

        <b-col cols="2" class="col-checks">
          <label id="urgency" class="checkbox title-styles" align="center">
            Urgência?
            <input
              type="checkbox"
              v-model="form.urgency"
              :disabled="readonly || disabledCheckbox"
            />
            <span
              class="checkmark"
              :class="{ 'check-disable': readonly || disabledCheckbox }"
            >
              <Check />
            </span>
          </label>
          <b-tooltip v-if="disabledCheckbox" target="urgency" triggers="hover">
            Não é possível alterar esta opção quando existem procedimentos
            cadastrados
          </b-tooltip>
        </b-col>

        <b-col cols="2" class="col-checks">
          <label id="dhe" class="checkbox title-styles" align="center">
            DHE?
            <input
              type="checkbox"
              v-model="form.dhe"
              :disabled="readonly || disabledCheckbox"
            />
            <span
              class="checkmark"
              :class="{ 'check-disable': readonly || disabledCheckbox }"
            >
              <Check />
            </span>
          </label>
          <b-tooltip v-if="disabledCheckbox" target="dhe" triggers="hover">
            Não é possível alterar esta opção quando existem procedimentos
            cadastrados
          </b-tooltip>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <ProceduresModule
        :invoicingTissSetting="invoicing_tiss_setting"
        guide="fee"
        :settingsId="form.invoicing_tiss_setting_id"
        :items="form.items"
        :participationPercentage="
          invoicing_tiss_setting?.tiss_participation_percentage
        "
        :readonly="readonly"
        :form="form"
        :specialCases="
          invoicing_tiss_setting?.tiss_participation_percentage_special_case
        "
        :beneficiariesList="beneficiariesList"
        @addOrUpdateTissGuideItem="addOrUpdateTissGuideItem"
        @deleteTissGuideItem="deleteTissGuideItem"
      />

      <b-row>
        <b-col cols="12" class="mt-3">
          <label for="observation">Observação / Justificativa</label>
          <b-form-textarea
            maxlength="500"
            placeholder="Descrever"
            rows="2"
            max-rows="5"
            autocomplete="off"
            v-model="form.observation"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="6" class="mt-3">
          <label for="total_amount_fess">Valor total de honorários</label>
          <money :value="total" class="form-control" readonly />
        </b-col>

        <b-col cols="6" class="mt-3">
          <b-form-group>
            <label for="issue_date">Data de emissão</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.issue_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <b-form-invalid-feedback :state="validProp('issue_date')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="tissGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updateTissGuide"
          :disabled="alreadySent"
          v-if="tissGuide?.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          :disabled="alreadySent"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { TissBatch } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'FeeGuideModal',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Check: () => import('@/assets/icons/check.svg'),
    GuideDataModule: () => import('./Modules/GuideDataModule'),
    ProceduresModule: () => import('./Modules/ProceduresModule'),
    ProviderGuideInput: () =>
      import('@/components/Tiss/Generic/ModalComponents/ProviderGuideInput'),
    ContractedField: () =>
      import('@/components/Tiss/Guides/Modules/ContractedField')
  },
  props: {
    patient: Object,
    healthPlan: Object,
    clinicHealthPlanId: String || null,
    invoicing_tiss_setting: Object,
    appointmentId: String,
    tissGuide: Object,
    guideNumber: Number,
    hospitalizationGuidesNumbers: Array,
    providerGuideReadonly: Boolean,
    validateProviderGuide: Function,
    guideNumberExistsInBatch: Boolean,
    guideNumberExists: Boolean,
    providerGuideUpdated: Number,
    contracteds: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      contractedCodeTypes: require('./../Data/contractedCodeTypes.json'),
      validated: false,
      form: {},
      disabledCheckbox: false,
      beneficiariesList: [],
      healthPlanRequiredFields: {},
      alreadySent: false
    }
  },
  computed: {
    ...mapState('manageGuides', ['requiredFields']),
    hospitalizationGuideNumber() {
      return (
        this.hospitalizationGuidesNumbers &&
        this.hospitalizationGuidesNumbers.find(
          item => item.value === this.form.hosp_req_id
        )
      )
    },
    contractedCodeTypesComputed() {
      return this.contractedCodeTypes.filter(item => {
        return item.value !== 'CPF do contratado'
      })
    },
    readonly() {
      return Boolean(
        this.tissGuide.id &&
          (['Finalizada'].includes(this.tissGuide.situation) ||
            (this.tissGuide.tiss_batch_id &&
              this.tissGuide.tiss_batch?.status !==
                TissBatch.BATCH_STATUS_TO_SENT))
      )
    },
    mask() {
      return this.form.contracted_code_type &&
        this.form.contracted_code_type.value === 'CNPJ do contratado'
        ? '##.###.###/####-##'
        : '##############'
    },
    total() {
      return this.form.items.reduce((acc, item) => {
        return acc + item.total_value
      }, 0)
    }
  },
  methods: {
    async onShow() {
      await this.getHealthPlanRequiredFields()
      this.getPatientWallet()
      if (!this.tissGuide?.id) {
        this.setSelectsFields()
      }
    },
    setSelectsFields() {
      if (this.tissGuide.id) return
      const fields = {
        beneficiary_name: this.patient?.name,
        newborn_care: this.patient?.birthday
          ? this.moment(this.patient.birthday).add(1, 'M') >= this.moment()
          : false,

        contracted_code_type: this.invoicing_tiss_setting?.provider_code
          ? this.contractedCodeTypes.find(
              option =>
                option.label === this.invoicing_tiss_setting.provider_code
            )
          : null,
        operator_code: this.invoicing_tiss_setting?.tiss_using_guide_type
          ?.fee_guide
          ? this.invoicing_tiss_setting.hired_executant_code
          : null,
        hospital_name: this.invoicing_tiss_setting?.tiss_using_guide_type
          ?.fee_guide
          ? this.invoicing_tiss_setting.hired_executant_name
          : null,
        contractor_cnes: this.invoicing_tiss_setting?.tiss_using_guide_type
          ?.fee_guide
          ? this.invoicing_tiss_setting.CNES
          : null
      }

      this.form = {
        ...this.form,
        ...fields,
        ...this.getProfessionalContractor()
      }
    },
    getProfessionalContractor() {
      const professional =
        this.invoicing_tiss_setting?.invoicing_tiss_setting_professionals?.find(
          invoicingTissSettingProfessional => {
            return (
              invoicingTissSettingProfessional?.professional_id ===
              this.tissGuide?.professional_id_fill
            )
          }
        )
      const contractor = this.contracteds.find(item => {
        return item.operator_code === professional?.operator_code
      })
      return {
        contractor_code_performing:
          contractor?.operator_code ??
          this.invoicing_tiss_setting?.tiss_using_guide_type?.fee_guide
            ? this.invoicing_tiss_setting.hired_executant_code
            : null,
        performing_contractor_name:
          contractor?.value ??
          this.invoicing_tiss_setting?.tiss_using_guide_type?.fee_guide
            ? this.invoicing_tiss_setting.hired_executant_name
            : null,
        executer_cnes_code:
          contractor?.cnes ??
          this.invoicing_tiss_setting?.tiss_using_guide_type?.fee_guide
            ? this.invoicing_tiss_setting.CNES
            : null
      }
    },
    selectSolicitationGuide(item, field) {
      this.form[field] = item.value
    },
    getDefaultForm() {
      return {
        clinic_id: this.clinic.id,
        id: null,
        bank_account_id: this.invoicing_tiss_setting
          ? this.invoicing_tiss_setting.bank_account_id
          : null,

        invoicing_tiss_setting_id: this.invoicing_tiss_setting
          ? this.invoicing_tiss_setting.id
          : null,
        appointment_id: this.appointmentId,

        items: [],

        situation: null,
        tiss_invoice_id: null,

        ans: this.healthPlan?.ans_code,
        provider_guide: this.guideNumber,
        hosp_req_id: null,
        hosp_req_num: null,
        password: null,
        operator_guide_number: null,

        wallet_number: null,
        beneficiary_name: this.patient?.name,
        newborn_care: this.patient?.birthday
          ? this.moment(this.patient.birthday).add(1, 'M') >= this.moment()
          : false,

        contracted_code_type: this.invoicing_tiss_setting?.provider_code
          ? this.contractedCodeTypes.find(
              option =>
                option.label === this.invoicing_tiss_setting.provider_code
            )
          : null,
        operator_code: this.invoicing_tiss_setting?.tiss_using_guide_type
          ?.fee_guide
          ? this.invoicing_tiss_setting.hired_executant_code
          : null,
        hospital_name: this.invoicing_tiss_setting?.tiss_using_guide_type
          ?.fee_guide
          ? this.invoicing_tiss_setting.hired_executant_name
          : null,
        contractor_cnes: this.invoicing_tiss_setting?.tiss_using_guide_type
          ?.fee_guide
          ? this.invoicing_tiss_setting.CNES
          : null,

        ...this.getProfessionalContractor(),

        invoicing_start_day: null,
        invoicing_end_day: null,

        apartment: false,
        urgency: false,
        dhe: false,

        observation: null,
        total_amount_fess: 0,
        issue_date: null
      }
    },
    clearForm() {
      this.form = this.getDefaultForm()
      this.validated = false
    },
    isValidForm() {
      this.validated = true

      if (this.guideNumberExists) {
        this.$toast.error(
          'Este Nº de guia já existe, resolva o conflito para continuar.'
        )
        return false
      }

      if (this.form.tiss_invoice_id && this.hasItemWithoutProfessional()) {
        this.$toast.warning(
          'Não é possível atualizar/salvar guia com fatura relacionada sem que todos os itens adicionados estejam com profissional ativo e com valor total maior que R$0,00.'
        )
        return false
      }

      const requiredTissFields = Object.keys(this.healthPlanRequiredFields).length ? this.healthPlanRequiredFields : this.requiredFields

      if(Object.keys(requiredTissFields).length){
        const hasHospReq = Object.keys(requiredTissFields).some(key => key === 'hosp_req');
        if(hasHospReq) {
          requiredTissFields.hosp_req_num = requiredTissFields.hosp_req;
          delete requiredTissFields.hosp_req;
        }
      }

      const procedureKeys = [
        'execution_date',
        'start_time',
        'end_time',
        'pathway',
        'technique'
      ]

      if (
        Object.keys(requiredTissFields)
          .filter(key => procedureKeys.includes(key))
          .some(
            key =>
              requiredTissFields[key] &&
              (!this.form.items.length ||
                this.form.items.some(item => !item[key]))
          )
      ) {
        this.$toast.warning(
          'Existem campos obrigatórios não preenchidos nos procedimentos.'
        )
        return false
      }

      const feeGuideKeys = [
        'provider_guide',
        'wallet_number',
        'beneficiary_name',
        'password',
        'hosp_req_num',
        'performing_contractor_name',
        'invoicing_start_day',
        'invoicing_end_day',
        'issue_date'
      ]

      return !Object.keys(requiredTissFields)
        .filter(key => feeGuideKeys.includes(key))
        .some(key => requiredTissFields[key] && !this.form[key])
    },
    hasItemWithoutProfessional() {
      return (
        this.form.items.some(item => item?.professionals?.length === 0) ||
        this.form.items.some(item =>
          item?.professionals.some(professional => professional?.value <= 0)
        )
      )
    },
    print() {
      this.$emit('print', this.tissGuide)
    },
    getSelectedValue() {
      if (typeof this.form.tiss_invoice_id === 'object')
        this.form.tiss_invoice_id = this.form.tiss_invoice_id?.value ?? null
      if (typeof this.form.contracted_code_type === 'object')
        this.form.contracted_code_type = this.form.contracted_code_type?.value
    },
    async createGuide() {
      if (!this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios')
        return
      }

      this.alreadySent = true

      const isLoading = this.$loading.show()
      try {
        this.getSelectedValue()
        const checkProfessionals = this.checkProfessionals();
        if(checkProfessionals){
          this.form.patient_id = this.patient.id
          const response = await this.api.createFeeGuide(this.form)
          this.$toast.success('Guia de honorários criada com sucesso!')
          this.$emit('refresh-tiss-guides', {
            id: response.data.id,
            guide_id: this.tissGuide.id
          })
          this.closeModal()
        } else {
          this.$swal.fire({
            title: 'Aviso',
            html: `
              <div>
                Você possui um ou mais procedimentos sem um médico executante. 
                <span style="font-weight: bold; color: #305BF2">
                  Defina um médico executante para prosseguir!  
                </span>
              </div>
            `,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: 'transparent',
            cancelButtonText: '<span style="color: darkblue;">Voltar</span>',
            cancelButtonHoverColor: '#ff0000',
            showCloseButton: true,
            reverseButtons: true,
            customClass: {
              popup: "custom-swal-professional-guide",
            },
          })         
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.alreadySent = false
        isLoading.hide()
      }
    },
    async updateTissGuide() {
      if (!this.isValidForm()) return

      this.alreadySent = true
      const isLoading = this.$loading.show()
      try {
        this.getSelectedValue()
        const checkProfessionals = this.checkProfessionals();
        if(checkProfessionals){
          this.form.clinic_id = this.clinic.id
          const response = await this.api.updateFeeGuide(
            this.tissGuide.guide.id,
            this.form
          )
          this.$toast.success('Guia de honorários atualizada com sucesso!')
          this.$emit('refresh-tiss-guides', {
            id: response.data.id,
            guide_id: this.tissGuide.id
          })
        } else {
          this.$swal.fire({
            title: 'Aviso',
            html: `
              <div>
                Você possui um ou mais procedimentos sem um médico executante. 
                <span style="font-weight: bold; color: #305BF2">
                  Defina um médico executante para prosseguir!  
                </span>
              </div>
            `,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: 'transparent',
            cancelButtonText: '<span style="color: darkblue;">Voltar</span>',
            cancelButtonHoverColor: '#ff0000',
            showCloseButton: true,
            reverseButtons: true,
            customClass: {
              popup: "custom-swal-professional-guide",
            },
          })
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.alreadySent = false
        isLoading.hide()
      }
    },
    closeModal() {
      this.clearForm()
      this.$emit('onHide')
      this.$bvModal.hide('fee_guide_modal')
    },
    openHospitalizationRequestModal() {
      this.$emit('open-hospitalization-request-modal')
    },
    getPatientWallet() {
      if (this.tissGuide?.id) return

      const personPlan = this.patient?.person_health_plans?.find(
        plan => plan?.health_plan_id === this.healthPlan?.id
      )

      this.form.wallet_number = personPlan?.registration
    },
    selectContracted(value) {
      this.form.performing_contractor_name = value?.label
      this.form.contractor_code_performing = value?.operator_code
      this.form.executer_cnes_code = value?.cnes
    },
    selectContractedProcedure(value) {
      this.form.hospital_name = value?.label
      this.form.contracted_code_type = {
        label: value?.code_type,
        value: value?.code_type
      }
      this.form.operator_code = value?.operator_code
      this.form.contractor_cnes = value?.cnes
    },
    validProp(prop) {
      const requiredTissFields = Object.keys(this.healthPlanRequiredFields).length ? this.healthPlanRequiredFields : this.requiredFields
      if(Object.keys(requiredTissFields).length){
        const hasHospReq = Object.keys(requiredTissFields).some(key => key === 'hosp_req');
        if(hasHospReq) {
          requiredTissFields.hosp_req_num = requiredTissFields.hosp_req;
          delete requiredTissFields.hosp_req;
        }
      }
      if (!this.validated || !requiredTissFields[prop]) return null
      return !!this.form[prop]
    },
    filterProcedures(items) {
      return items.filter(item => !item.id || item.guide_id === this.form.id)
    },
    addOrUpdateTissGuideItem(tissGuideItem) {
      const items = Object.assign([], this.form.items ?? [])
      tissGuideItem.index > -1 ? 
        items.splice(tissGuideItem.index, 1, tissGuideItem) :
        items.push(tissGuideItem)
      this.form = { ...this.form, items }
    },
    deleteTissGuideItem(index) {
      const items = Object.assign([], this.form.items ?? [])
      items.splice(index, 1)
      this.form = { ...this.form, items }
    },
    checkProfessionals(){
      if(this.form.items.length === 0) return true;
      let check = true
      this.form.items.forEach(el => {
        if(el.professionals?.some(el => !el.professional_id)){
          check = false;
        }
      })
      return check;
    },
    async getHealthPlanRequiredFields() {
      if(this.clinicHealthPlanId){
        try {
          this.api.getHealthPlanRequiredFields(this.clinicHealthPlanId).then(({ data }) => {
            if(data.length){
              const temp = [];
              data.forEach(item => {
                temp[item.name] = Boolean(item.pivot.active);
              });
              this.healthPlanRequiredFields = temp;
            }
          })
        } catch (error) {
          console.error('Erro ao buscar requiredFields: ' + error)
        }
      }
    }
  },
  watch: {
    tissGuide: {
      handler(newValue) {
        if (newValue.guide_type === 'App\\Models\\TissGuide\\TissGuideFee') {
          this.form = {
            ...this.form,
            ...newValue.guide
          }

          if(newValue.clinic_id){
            this.api.getBeneficiariesByClinicId(newValue.clinic_id).then(({data}) => {
              if(data && data.length > 0){
                this.beneficiariesList = data;
              }
            })
          }

          this.form.provider_guide = newValue.provider_guide
          this.form.invoicing_tiss_setting_id =
            newValue.invoicing_tiss_setting_id
          this.form.tiss_invoice_id = newValue.tiss_invoice_id
          this.form.tiss_guide_id = newValue.id
          this.form.situation = newValue.situation
          this.form.invoicing_start_day = this.moment(
            this.form.invoicing_start_day
          ).toDate()
          this.form.invoicing_end_day = this.moment(
            this.form.invoicing_end_day
          ).toDate()
          this.form.issue_date = this.moment(this.form.issue_date).toDate()
          this.form.items = newValue.items
          this.form.contracted_code_type =
            this.contractedCodeTypesComputed.find(
              option => option.value === newValue.guide.contracted_code_type
            )
        } else if (newValue.items?.length) {
          !this.form.ans && (this.form = this.getDefaultForm())
          this.getPatientWallet()
          this.form.invoicing_tiss_setting_id =
            newValue.invoicing_tiss_setting_id
          this.form.items = this.filterProcedures(newValue.items)
          this.form.items.map(item => {
            if (item?.item?.procedure_id && !item.item_type) {
              item.item_type = 'App\\Models\\Procedure'
              item.item_id = item?.item?.procedure_id
            }
          })
        } else {
          this.clearForm()
        }
      },
      immediate: true
    },
    guideNumber() {
      if (!Object.keys(this.tissGuide).length) {
        this.form = this.getDefaultForm()
      }
    },
    'form.items': {
      handler(newValue) {
        this.disabledCheckbox = newValue.length > 0
        if (newValue.length) {
          let total = 0
          newValue.map(item => {
            total += item.professionals.reduce((total, professional) => {
              return professional?.value ? total + professional?.value : total
            }, 0)
          })
          this.form.total_amount_fess = total
        } else {
          this.form.total_amount_fess = 0
        }
      },
      immediate: true,
      deep: true
    },
    'form.provider_guide': {
      handler(newValue) {
        if (newValue) {
          const form = {
            id: this.tissGuide.id,
            provider_guide: newValue,
            patient: {
              name: this.form.beneficiary_name
            }
          }
          this.$emit('update-form', {
            form: form,
            providerGuide: this.providerGuideUpdated ?? this.guideNumber
          })
        }
      },
      deep: true,
      immediate: true
    },
    patient: {
      handler(value) {
        if (value?.id) {
          this.form.beneficiary_name = this.form.beneficiary_name || value.name
          this.form.beneficiary_social_name =
            this.form.beneficiary_social_name || value?.nickname
          this.form.newborn_care =
            this.form.newborn_care || value?.birthday
              ? this.moment(this.patient.birthday).add(1, 'M') >= this.moment()
              : false
        }
      },
      deep: true,
      immediate: true
    },
    providerGuideUpdated: {
      handler(newValue) {
        if (newValue) {
          this.form.provider_guide = newValue
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#fee_guide_modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0 0 4px 0;
  }

  .col-checks {
    margin-top: 3% !important;
  }
  .form-title {
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }

  .multiselect {
    .action {
      border-top: 1px solid var(--neutral-200);
      color: var(--blue-500);
      display: flex;
      align-items: center;

      .icon {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
        margin-right: 16px;
      }
    }
  }
}
</style>
